@import url("https://fonts.googleapis.com/css2?family=Anton&family=Bangers&family=Bitter&family=Exo&family=Magra&family=Permanent+Marker&family=Roboto&family=Roboto+Condensed&family=Suez+One&family=Teko&display=swap");

body {
  margin: 0;
  padding: 0%;
}

#root {
  /* width: 100%; */
  height: 100vh;
}

@font-face {
  font-family: "Poppins";
  font-weight: 400;
  src: url("./assets/font/Poppins-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  src: url("./assets/font/Poppins-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  src: url("./assets/font/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  src: url("./assets/font/Poppins-ExtraBold.ttf") format("truetype");
}

.scrollable-content::-webkit-scrollbar {
  width: 3px;
  height: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* or any color you prefer */
}

/* Customize the scrollbar thumb */
.scrollable-content::-webkit-scrollbar-thumb {
  background-color: #888; /* or any color you prefer */
  border-radius: 5px;
}

/* Customize the scrollbar thumb on hover */
.scrollable-content::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* or any color you prefer */
}

.lbgqDY {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  border-bottom: 1px solid #707070;
}
