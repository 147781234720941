.ck .ck-content {
    font-family: 'Poppins', sans-serif; /* Font family */
    font-size: 16px; /* Font size */
    color: #000000; /* Text color */
    background-color: rgba(0, 0, 0, 0); /* Background color */
  }
 /* line spacing should minimum */
  .ck.ck-content {
    line-height: 1.2;
  }

  /* add min and max height for input and after that scrool */
  .ck.ck-editor__editable_inline {
    min-height: 94px;
    max-height: 94px;
    overflow-y: auto;
  }
  